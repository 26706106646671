import React from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as GaladiseLogo } from '../../../../resources/svg/GaladiseLogo.svg';
import { NavLink } from 'react-router-dom';

const Root = styled(Grid)(({ theme }) => ({
	width: 300,
	padding: 20,
	backgroundColor: theme.palette.background.primary,
	borderRight: `solid 1px ${theme.palette.separator.primary}`,
	[theme.breakpoints.down('md')]: {
		display: 'none'
	}
}));

const BrandLogo = styled(GaladiseLogo)(({ theme }) => ({
	width: 120,
	height: 'auto',
	marginBottom: 40,
	marginLeft: 15,
	marginTop: 10,
	fill: theme.palette.primary.main
}));

const MenuStack = styled(Grid)(({ theme }) => ({
	width: '100%',
	padding: 15,
	display: 'flex',
	flexDirection: 'column',
	gap: 15
}));

const MenuItem = styled(NavLink)(({ theme }) => ({
	marginTop: 7,
	textDecoration: 'none',
	fontSize: '1.1rem',
	fontFamily: 'Quicksand',
	fontWeight: 500,
	color: theme.palette.text.tertiary,
	opacity: 0.7,
	transition: 'all 0.2s',
	position: 'relative',
	'&.active': {
		color: theme.palette.text.primary,
		opacity: 1
	},
	'&:active': {
		color: theme.palette.text.primary,
		opacity: 0.5
	}
}));

function SideMenu() {
	return (
		<Root>
			<BrandLogo />
			<MenuStack>
				<MenuItem to="/dashboard" end>
					Home
				</MenuItem>
				<MenuItem to="/dashboard/galleries">Galleries</MenuItem>
			</MenuStack>
		</Root>
	);
}

export default SideMenu;
