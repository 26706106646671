import React from 'react';
import { useState } from 'react';
import { Grid, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ContentCopyRounded } from '@mui/icons-material';
import { DotLottiePlayer } from '@dotlottie/react-player';

const CopyContainer = styled(Grid)(({ theme }) => ({
	width: '100%',
	padding: 5,
	backgroundColor: theme.palette.background.secondary,
	borderRadius: 5,
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center'
}));

const CopyLink = styled(Grid)(({ theme }) => ({
	maxWidth: 220,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	marginInlineStart: 10,
	fontSize: '0.9rem',
	opacity: 0.6
}));

const CopyButton = styled(Grid)(({ theme }) => ({
	width: 30,
	height: 30,
	backgroundColor: '#FFF',
	borderRadius: 5,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	cursor: 'pointer',
	transition: 'opacity 0.2s',
	'&:hover': {
		opacity: 0.6
	},
	'&:active': {
		opacity: 0.3
	}
}));

const LottieContainer = styled(Grid)(({ theme }) => ({
	width: 30,
	height: 30,
	backgroundColor: '#FFF',
	borderRadius: 5,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
}));

const LottieWrapper = styled(Grid)(({ theme }) => ({
	width: 24,
	height: 24
}));

const CopyIcon = styled(ContentCopyRounded)(({ theme }) => ({
	width: 18,
	height: 18
}));

function LinkDisplay({ url, ...props }) {
	const [copied, setCopied] = useState(false);

	const copyToClipboard = () => {
		navigator.clipboard.writeText(url);
		setCopied(true);

		setTimeout(() => {
			setCopied(false);
		}, 3000);
	};

	return (
		<CopyContainer {...props}>
			<CopyLink>{url}</CopyLink>
			{!copied && (
				<Tooltip title="Copy link">
					<CopyButton onClick={copyToClipboard}>
						<CopyIcon />
					</CopyButton>
				</Tooltip>
			)}
			{copied && (
				<LottieContainer>
					<LottieWrapper>
						<DotLottiePlayer src="/lottie/Success.lottie" autoplay speed={1.5} />
					</LottieWrapper>
				</LottieContainer>
			)}
		</CopyContainer>
	);
}

export default LinkDisplay;
