import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import MobileMenuItems from './MobileMenuItems';

const Root = styled(motion.div)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	backgroundColor: theme.palette.surface.contrast,
	position: 'fixed',
	[theme.breakpoints.up('md')]: {
		display: 'none'
	}
}));

const MenuContainer = styled(motion.div)(({ theme }) => ({
	width: '100%'
}));

function MobileMenuView({ open, setOpen }) {
	const [showSubItem, setShowSubItem] = useState(false);

	const [top, left, right, borderRadius] = open ? [0, 0, 0, 0] : [20, 20, 20, 20];
	const height = open ? '100dvh' : 70;
	const opacity = open ? 1 : 0;

	useEffect(() => {
		if (open) {
			setShowSubItem(true);
		} else {
			setTimeout(() => {
				setShowSubItem(false);
			}, 300);
		}
	}, [open]);

	return (
		<Root
			animate={{
				top,
				left,
				right,
				height,
				borderRadius
			}}
			initial={{ top: 20 }}
			transition={{ ease: [0.77, 0, 0.175, 1], duration: 0.3 }}
		>
			<MenuContainer
				animate={{
					opacity: opacity
				}}
				initial={{ opacity: 0 }}
				transition={{ ease: [0.77, 0, 0.175, 1], duration: 0.1 }}
			>
				<MobileMenuItems show={showSubItem} setOpen={setOpen} />
			</MenuContainer>
		</Root>
	);
}

export default MobileMenuView;
