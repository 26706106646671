import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import SelectButton from '../../../../../Generic/SelectButton';
import DataCard from '../DataCard';
import AnalyticsChart from '../AnalyticsChart/AnalyticsChart';

const GraphContainer = styled(Grid)(({ theme }) => ({
	width: '100%',
	padding: 20,
	borderRadius: 20,
	backgroundColor: theme.palette.surface.contrast,
	boxShadow: `0 0 20px -10px ${theme.palette.separator.primary}`
}));

const HeaderContainer = styled(Grid)(({ theme }) => ({
	width: '100%',
	display: 'flex',
	justifyContent: 'space-between'
}));

const StatsContainer = styled(Grid)(({ theme }) => ({
	width: '100%',
	marginTop: 20,
	display: 'flex',
	justifyContent: 'space-between',
	flexWrap: 'wrap',
	gap: '10px'
}));

const Title = styled(Typography)(({ theme }) => ({
	fontSize: '1.1rem',
	fontFamily: 'Quicksand',
	fontWeight: 600
}));

const selectButtonOptions = [
	{
		label: '30D',
		key: 30
	},
	{
		label: '7D',
		key: 7
	},
	{
		label: '24H',
		key: 1
	}
];

function GalleryAnalytics({ views, downloads }) {
	const theme = useTheme();
	const [days, setDays] = useState(selectButtonOptions[0].key);

	const daysCutoff = new Date();
	daysCutoff.setDate(daysCutoff.getDate() - days);
	daysCutoff.setHours(0, 0, 0, 0);

	const totalViews = views?.filter((view) => new Date(view.createdOn) >= daysCutoff);
	const totalDownloads = downloads?.filter(
		(download) => new Date(download.createdOn) >= daysCutoff
	);
	const uniqueViews = findUnique(views?.filter((view) => new Date(view.createdOn) >= daysCutoff));
	const uniqueDownloads = findUnique(
		downloads?.filter((download) => new Date(download.createdOn) >= daysCutoff)
	);

	/**
	 * Filters an array of analytic data to return only the first instance per clientID.
	 * @param {Array} data - Array of analytic data objects.
	 * @returns {Array} - Array of unique instances based on clientID.
	 */
	function findUnique(data) {
		if (!data) return [];

		const uniqueInstances = [];
		const seenClientIDs = new Set();

		for (const item of data) {
			if (!seenClientIDs.has(item.clientID)) {
				uniqueInstances.push(item);
				seenClientIDs.add(item.clientID);
			}
		}

		return uniqueInstances;
	}

	return (
		<GraphContainer>
			<HeaderContainer>
				<Title>Gallery Analytics</Title>
				<SelectButton options={selectButtonOptions} active={days} setActive={setDays} />
			</HeaderContainer>

			<StatsContainer>
				<DataCard
					amount={totalViews?.length}
					title="Gallery Views"
					color={theme.palette.info.main}
				/>
				<DataCard
					amount={uniqueViews?.length}
					title="Unique Views"
					color={theme.palette.info.main}
				/>
				<DataCard
					amount={totalDownloads?.length}
					title="Gallery Downloads"
					color={theme.palette.info.main}
				/>
				<DataCard
					amount={uniqueDownloads?.length}
					title="Unique Downloads"
					color={theme.palette.info.main}
				/>
			</StatsContainer>

			<AnalyticsChart
				totalViews={totalViews}
				totalDownloads={totalDownloads}
				uniqueViews={uniqueViews}
				uniqueDownloads={uniqueDownloads}
				days={days}
			/>
		</GraphContainer>
	);
}

export default GalleryAnalytics;
