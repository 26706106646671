import React from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import UserProfile from './UserProfile';
import { useLocation } from 'react-router-dom';
import MobileMenu from './MobileMenu';

const Root = styled(Grid)(({ theme }) => ({
	width: 'calc(100% - 40px)',
	height: 70,
	minHeight: 70,
	margin: 20,
	marginBottom: 0,
	borderRadius: 20
}));

const Base = styled(Grid)(({ theme }) => ({
	width: 'calc(100% - 40px)',
	padding: '10px 15px',
	borderRadius: 20,
	backgroundColor: theme.palette.surface.contrast,
	boxShadow: `0 0 20px -10px ${theme.palette.separator.primary}`,
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	zIndex: 1000,
	position: 'absolute',
	top: 20,
	right: 20
}));

const Title = styled(Typography)(({ theme }) => ({
	marginLeft: 15,
	fontSize: '1.4rem',
	fontFamily: 'Quicksand',
	fontWeight: 500,
	[theme.breakpoints.down('md')]: {
		display: 'none'
	}
}));

function AppHeader() {
	const location = useLocation();

	const path = location.pathname.split('/');
	const title = path[2] || 'Home';
	const formattedTitle = title.charAt(0).toUpperCase() + title.slice(1);

	return (
		<Root>
			<Base>
				<MobileMenu />
				<Title>{formattedTitle}</Title>
				<UserProfile />
			</Base>
		</Root>
	);
}

export default AppHeader;
