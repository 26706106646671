import React from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const Root = styled(Grid)(({ theme }) => ({
	width: '100%',
	height: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
}));

function Home() {
	return <Root>Home</Root>;
}

export default Home;
