import React, { useState } from 'react';
import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as GaladiseLogo } from '../../resources/svg/GaladiseLogo.svg';
import { EastRounded } from '@mui/icons-material';
import PasswordInput from './PageComponents/Login/PasswordInput';
import { login } from '../../services/authService';
import { PublicRoute } from '../Router';
import { useAuth } from '../../hooks/AuthContext';

const Root = styled(Grid)(({ theme }) => ({
	width: '100%',
	height: '100dvh',
	backgroundColor: theme.palette.background.primary,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column'
}));

const Title = styled(Typography)(({ theme }) => ({
	fontSize: '1.2rem',
	fontWeight: 600
}));

const ComapnyLogo = styled(GaladiseLogo)(({ theme }) => ({
	width: 200,
	height: 'auto',
	marginBottom: 40,
	fill: theme.palette.primary.main
}));

const LoginCard = styled(Grid)(({ theme }) => ({
	width: 450,
	maxWidth: '90vw',
	height: 'auto',
	padding: '40px 20px',
	borderRadius: 20,
	boxShadow: `0 0 40px ${theme.palette.surface.dark}`,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	gap: 40
}));

const InputsContainer = styled(Grid)(({ theme }) => ({
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	gap: 20
}));

const InputField = styled(TextField)(({ theme }) => ({
	width: '80%'
}));

const LoginButton = styled(Button)(({ theme }) => ({
	width: '60%',
	backgroundColor: theme.palette.primary.main,
	color: theme.palette.text.contrast,
	borderRadius: 30,
	'&:hover': {
		backgroundColor: theme.palette.primary.light
	},
	'&:disabled': {
		color: theme.palette.text.contrast,
		opacity: 0.5
	}
}));

const NextArrow = styled(EastRounded)(({ theme }) => ({
	width: 18,
	marginInlineStart: 10,
	color: theme.palette.text.contrast
}));

const Loader = styled(CircularProgress)(({ theme }) => ({
	width: '14px !important',
	height: '14px !important',
	marginInlineStart: 14,
	color: theme.palette.text.contrast
}));

function Login() {
	const [data, setData] = useState({ email: '', password: '' });
	const [loading, setLoading] = useState(false);
	const { setLoggedIn } = useAuth();

	/**
	 * Wraps the loading function and sets the loading state.
	 */
	const handleLogin = async () => {
		setLoading(true);
		const authenticated = await login(data?.email, data?.password);

		if (authenticated) {
			setLoggedIn();
		}

		setLoading(false);
	};

	return (
		<PublicRoute>
			<Root>
				<ComapnyLogo />
				<LoginCard>
					<Title>Welcome Back!</Title>
					<InputsContainer>
						<InputField
							value={data?.email}
							onChange={(e) => setData({ ...data, email: e?.target?.value })}
							size="small"
							variant="standard"
							placeholder="example@gmail.com"
							type="email"
							autoComplete="email"
						/>
						<PasswordInput
							value={data?.password}
							onChange={(e) => setData({ ...data, password: e?.target?.value })}
						/>
					</InputsContainer>
					<LoginButton onClick={handleLogin} disabled={loading}>
						Login
						{!loading && <NextArrow />}
						{loading && <Loader />}
					</LoginButton>
				</LoginCard>
			</Root>
		</PublicRoute>
	);
}

export default Login;
