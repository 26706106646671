import React from 'react';
import { Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

const Root = styled(Skeleton)(({ theme }) => ({
	width: '100%',
	height: 586,
	borderRadius: 20
}));

function GalleryAnalyticsSkeleton() {
	return <Root variant="rectangular" />;
}

export default GalleryAnalyticsSkeleton;
