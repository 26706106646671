import React, { useEffect, useState } from 'react';
import { Grid, Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAppData } from '../../../../../hooks/AppDataContext';
import { useParams } from 'react-router-dom';
import { getGalleries, getGalleryStats } from '../../../../../services/galleryService';
import { GalleryAnalyticCard, GalleryAnalyticCardSkeleton } from './GalleryAnalyticCard';
import { ReactComponent as EyeIcon } from '../../../../../resources/svg/eye.svg';
import { ReactComponent as DownloadIcon } from '../../../../../resources/svg/download.svg';
import { ReactComponent as ClockIcon } from '../../../../../resources/svg/clock.svg';
import { ReactComponent as ImageIcon } from '../../../../../resources/svg/image.svg';
import { GalleryAnalytics, GalleryAnalyticsSkeleton } from './GalleryAnalytics';
import GalleryControl from './GalleryControl';
import GalleryControlSkeleton from './GalleryControl/GalleryControlSkeleton';

const Root = styled(Grid)(({ theme }) => ({
	display: 'flex',
	padding: 20,
	flexDirection: 'column',
	gap: '15px'
}));

const TitleContainer = styled(Grid)(({ theme }) => ({
	padding: '10px 12px',
	display: 'flex',
	flexDirection: 'column',
	gap: '5px',
	[theme.breakpoints.down('sm')]: {
		alignItems: 'center'
	}
}));

const StatsContainer = styled(Grid)(({ theme }) => ({
	display: 'flex',
	gap: '12px',
	[theme.breakpoints.down('md')]: {
		flexWrap: 'wrap',
		gap: '10px'
	}
}));

const Title = styled(Typography)(({ theme }) => ({
	fontSize: '1.5rem',
	fontFamily: 'Quicksand',
	fontWeight: 500,
	[theme.breakpoints.down('md')]: {
		textAlign: 'center'
	}
}));

const Description = styled(Typography)(({ theme }) => ({
	fontSize: '1rem',
	fontFamily: 'Quicksand',
	fontWeight: 500,
	color: theme.palette.text.secondary,
	[theme.breakpoints.down('md')]: {
		textAlign: 'center'
	}
}));

const TitleSkeleton = styled(Skeleton)(({ theme }) => ({
	width: 150,
	fontSize: '1.5rem',
	fontFamily: 'Quicksand'
}));

const DescriptionSkeleton = styled(Skeleton)(({ theme }) => ({
	width: 250,
	fontSize: '1rem',
	fontFamily: 'Quicksand'
}));

function GalleryDetails() {
	const { galleries, setGalleries } = useAppData();
	const { id } = useParams();
	const [loaded, setLoaded] = useState(false);
	const [stats, setStats] = useState({ data: [], loading: true, loadedAt: 0 });

	const fetchGalleries = async () => {
		if (!galleries.loadedAt) {
			setGalleries({ ...galleries, loading: true });
			const data = await getGalleries();
			setGalleries({ data, loadedAt: Date.now(), loading: false });
		}
	};

	const fetchStats = async () => {
		const data = await getGalleryStats(id);
		setStats({ data, loadedAt: Date.now(), loading: false });
	};

	useEffect(() => {
		fetchGalleries();
		fetchStats();
	}, []);

	useEffect(() => {
		if (galleries.loadedAt) {
			setLoaded(true);
		} else {
			setLoaded(false);
		}
	}, [galleries.loadedAt]);

	const gallery = galleries?.data?.find((g) => g._id === id);

	const remainingDays = (timestamp) => {
		if (!timestamp) {
			return '-';
		}
		const now = Date.now();
		const diffInMilliseconds = new Date(timestamp).getTime() - now;
		const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
		return Math.max(diffInDays, 0);
	};

	const loading = !(loaded && !stats.loading);

	return (
		<Root>
			<TitleContainer>
				{!loading && (
					<>
						<Title>{gallery.title}</Title>
						<Description>{gallery.description}</Description>
					</>
				)}
				{loading && (
					<>
						<TitleSkeleton variant="text" />
						<DescriptionSkeleton variant="text" />
					</>
				)}
			</TitleContainer>
			<StatsContainer>
				{!loading && (
					<>
						<GalleryAnalyticCard
							amount={stats.data.views?.length}
							title="All Time Views"
							Icon={EyeIcon}
						/>
						<GalleryAnalyticCard
							amount={stats.data.downloads?.length}
							title="All Time Downloads"
							Icon={DownloadIcon}
						/>
						<GalleryAnalyticCard
							amount={stats.data.totalImages}
							title="Gallery Images"
							Icon={ImageIcon}
						/>
						<GalleryAnalyticCard
							amount={remainingDays(gallery.expiration)}
							title="Days Remaining"
							Icon={ClockIcon}
						/>
					</>
				)}
				{loading && (
					<>
						<GalleryAnalyticCardSkeleton />
						<GalleryAnalyticCardSkeleton />
						<GalleryAnalyticCardSkeleton />
						<GalleryAnalyticCardSkeleton />
					</>
				)}
			</StatsContainer>
			{!loading && <GalleryControl gallery={gallery} />}
			{loading && <GalleryControlSkeleton />}
			{!loading && (
				<GalleryAnalytics views={stats.data.views} downloads={stats.data.downloads} />
			)}
			{loading && <GalleryAnalyticsSkeleton />}
		</Root>
	);
}

export default GalleryDetails;
