import React from 'react';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

const MenuItem = styled(NavLink)(({ theme }) => ({
	margin: '1.8vh',
	textDecoration: 'none',
	fontFamily: 'Quicksand',
	fontSize: '2rem',
	fontWeight: 400,
	color: theme.palette.text.primary,
	transition: 'all 0.2s',
	position: 'relative',
	opacity: 0.9,
	'&:hover': {
		color: theme.palette.text.primary,
		opacity: 0.7
	},
	'&:active': {
		color: theme.palette.text.primary,
		opacity: 0.3
	},
	'&.active': {
		color: theme.palette.text.primary,
		opacity: 0.3
	},
	'&::after': {
		content: "''",
		position: 'absolute',
		width: '100%',
		transform: 'scaleX(0)',
		height: 2,
		bottom: 0,
		left: 0,
		backgroundColor: theme.palette.primary.contrastText,
		transformOrigin: 'bottom right',
		transition: 'transform 0.25s ease-out',
		opacity: 0.7
	},
	'&:hover::after': {
		transform: 'scaleX(1)',
		transformOrigin: 'bottom left'
	}
}));

function MobileMenuItem({ to, label, onClick }) {
	return (
		<MenuItem onClick={onClick} to={to} end>
			{label}
		</MenuItem>
	);
}

export default MobileMenuItem;
