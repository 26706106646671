import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAppData } from '../../../../../hooks/AppDataContext';
import { getGalleries } from '../../../../../services/galleryService';
import { GalleryCard, GalleryCardSkeleton } from './GalleryCard';

const Root = styled(Grid)(({ theme }) => ({
	gap: '19px',
	display: 'flex',
	padding: 20,
	flexWrap: 'wrap'
}));

function Galleries() {
	const { galleries, setGalleries } = useAppData();

	const fetchGalleries = async () => {
		const timestamp = Date.now();

		if (timestamp - galleries.loadedAt > 10 * 60 * 1000) {
			setGalleries({ ...galleries, loading: true });
			const data = await getGalleries();
			setGalleries({ data, loadedAt: Date.now(), loading: false });
		}
	};

	useEffect(() => {
		fetchGalleries();
	}, []);

	return (
		<Root>
			{!galleries.loading &&
				galleries.data.map((gallery) => (
					<GalleryCard
						title={gallery.title}
						description={gallery.description}
						img={gallery.thumbnail}
						galleryID={gallery._id}
						key={gallery._id}
					/>
				))}
			{galleries.loading && [1, 2, 3].map((i) => <GalleryCardSkeleton key={i} />)}
		</Root>
	);
}

export default Galleries;
