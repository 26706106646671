import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const Base = styled(Grid)(({ theme }) => ({
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column'
}));

function Root() {
	const navigate = useNavigate();
	useEffect(() => {
		navigate('./login');
	}, []);
	return <Base></Base>;
}

export default Root;
