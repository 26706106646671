import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Pages/PageComponents/Dashboard/Home/Home';
import Galleries from './Pages/PageComponents/Dashboard/Galleries/Galleries';
import PageNotFound from './Pages/PageNotFound';
import GalleryDetails from './Pages/PageComponents/Dashboard/GalleryDetails/GalleryDetails';

function DashboardRoutes() {
	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/galleries" element={<Galleries />} />
			<Route path="/galleries/:id" element={<GalleryDetails />} />
			<Route path="*" element={<PageNotFound />} />
		</Routes>
	);
}

export default DashboardRoutes;
