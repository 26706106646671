import React from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Root = styled(Grid)(({ theme }) => ({
	display: 'flex',
	width: 'calc(25% - 9px)',
	padding: 15,
	border: `1px solid ${theme.palette.surface.main}`,
	borderRadius: 20,
	flexDirection: 'column',
	[theme.breakpoints.down('sm')]: {
		width: 'calc(50% - 5px)'
	}
}));

const Title = styled(Typography)(({ theme }) => ({
	fontSize: '1.7rem',
	fontFamily: 'Quicksand',
	fontWeight: 700,
	color: theme.palette.primary.main
}));

const Description = styled(Typography)(({ theme }) => ({
	fontSize: '0.9rem',
	fontFamily: 'Quicksand',
	fontWeight: 500,
	color: theme.palette.primary.light
}));

function DataCard({ amount, title, color }) {
	return (
		<Root>
			<Title>{amount}</Title>
			<Description>{title}</Description>
		</Root>
	);
}

export default DataCard;
