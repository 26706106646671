import React from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Root = styled(Grid)(({ theme }) => ({
	marginTop: 5,
	padding: 10,
	position: 'relative',
	zIndex: 600,
	cursor: 'pointer'
}));

const MainLine = styled(motion.div)(({ theme }) => ({
	width: '2rem',
	height: 1.5,
	backgroundColor: theme.palette.primary.main,
	borderRadius: 1,
	position: 'relative'
}));

const TopLine = styled(motion.div)(({ theme }) => ({
	width: '2rem',
	height: 1.5,
	backgroundColor: theme.palette.primary.main,
	borderRadius: 1,
	position: 'absolute',
	left: 0,
	top: '-0.5rem'
}));

const BottomLine = styled(motion.div)(({ theme }) => ({
	width: '2rem',
	height: 1.5,
	backgroundColor: theme.palette.primary.main,
	borderRadius: 1,
	position: 'absolute',
	left: 0,
	top: '0.5rem'
}));

function MobileMenuToggle({ open, setOpen }) {
	const theme = useTheme();

	const topInitialStyle = {
		top: '-0.5rem',
		rotate: 0,
		backgroundColor: theme.palette.primary.main
	};

	const bottomInitialStyle = {
		top: '0.5rem',
		rotate: 0,
		backgroundColor: theme.palette.primary.main
	};

	const [backgroundColor, setBackgroundColor] = useState(theme.palette.primary.main);
	const [topLineStyle, setTopLineStyle] = useState(topInitialStyle);
	const [bottomLineStyle, setBottomLineStyle] = useState();

	useEffect(() => {
		if (open) {
			setBackgroundColor('#262f3500');
			setTopLineStyle({
				top: 0,
				rotate: 135
			});
			setBottomLineStyle({
				top: 0,
				rotate: -135
			});
		} else {
			setBackgroundColor(theme.palette.primary.main);
			setTopLineStyle(topInitialStyle);
			setBottomLineStyle(bottomInitialStyle);
		}
	}, [open]);

	return (
		<Root onClick={() => setOpen(!open)}>
			<MainLine
				animate={{ backgroundColor }}
				initial={{ backgroundColor: theme.palette.primary.main }}
				transition={{ ease: 'easeInOut', duration: 0.3 }}
			>
				<TopLine
					animate={topLineStyle}
					initial={topInitialStyle}
					transition={{ ease: 'easeInOut', duration: 0.3 }}
				/>
				<BottomLine
					animate={bottomLineStyle}
					initial={bottomInitialStyle}
					transition={{ ease: 'easeInOut', duration: 0.3 }}
				/>
			</MainLine>
		</Root>
	);
}

export default MobileMenuToggle;
