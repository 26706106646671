import React from 'react';
import { Grid, Tooltip, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LaunchRounded } from '@mui/icons-material';
import { ReactComponent as DeleteIcon } from '../../../../../../resources/svg/delete.svg';

const Root = styled(Grid)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	[theme.breakpoints.down('sm')]: {
		flexDirection: 'row'
	}
}));

const ViewButton = styled(IconButton)(({ theme }) => ({
	width: 35,
	height: 35,
	border: `1px solid ${theme.palette.primary.dark}`
}));

const LaunchRoundedIcon = styled(LaunchRounded)(({ theme }) => ({
	width: 16,
	height: 16,
	color: theme.palette.primary.dark
}));

const DeleteButton = styled(IconButton)(({ theme }) => ({
	width: 35,
	height: 35,
	border: `1px solid ${theme.palette.error.main}`
}));

const DeleteIconStyled = styled(DeleteIcon)(({ theme }) => ({
	width: 20,
	height: 20,
	stroke: theme.palette.error.main
}));

function GalleryControlActions({ onView, onDelete, ...props }) {
	return (
		<Root {...props}>
			<Tooltip title="View Gallery">
				<ViewButton onClick={onView}>
					<LaunchRoundedIcon />
				</ViewButton>
			</Tooltip>
			<Tooltip title="Delete Gallery">
				<DeleteButton onClick={onDelete}>
					<DeleteIconStyled />
				</DeleteButton>
			</Tooltip>
		</Root>
	);
}

export default GalleryControlActions;
