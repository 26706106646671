import React, { useState, useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';

const Root = styled(Grid)(({ theme }) => ({
	padding: 3,
	border: `1px solid ${theme.palette.separator.primary}`,
	borderRadius: 10,
	position: 'relative'
}));

const OptionButton = styled(Button)(({ theme }) => ({
	width: 40,
	minWidth: 0,
	padding: '2px 6px',
	fontSize: '0.8rem',
	borderRadius: 7,
	color: theme.palette.text.tertiary
}));

const ActiveBox = styled(motion.div)(({ theme }) => ({
	width: 40,
	height: 'calc(100% - 6px)',
	backgroundColor: theme.palette.surface.main,
	borderRadius: 7,
	position: 'absolute',
	top: 3,
	left: 3
}));

function SelectButton({ options, active, setActive }) {
	const [index, setIndex] = useState(0);

	useEffect(() => {
		setIndex(options?.findIndex((option) => option.key === active));
	}, [active]);

	return (
		<Root>
			<ActiveBox
				animate={{ x: index * 40 }}
				transition={{ type: 'spring', stiffness: 300, damping: 30 }}
			/>
			{options?.map((option) => (
				<OptionButton key={option.key} onClick={() => setActive(option.key)}>
					{option.label}
				</OptionButton>
			))}
		</Root>
	);
}

export default SelectButton;
