import React from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const Root = styled(motion.div)(({ theme }) => ({
	width: 'calc(33.33vw - 132px)',
	height: 'calc(33.33vw - 30px)',
	display: 'flex',
	flexDirection: 'column',
	backgroundColor: theme.palette.background.primary,
	border: `solid 1px ${theme.palette.separator.primary}`,
	borderRadius: 20,
	cursor: 'pointer',
	userSelect: 'none',
	position: 'relative',
	overflow: 'hidden',
	[theme.breakpoints.down('lg')]: {
		width: 'calc(50vw - 187px)',
		height: 'calc(50vw - 60px)'
	},
	[theme.breakpoints.down('md')]: {
		width: 'calc(100vw - 65px)',
		height: 'calc(100vw + 50px)',
		marginLeft: 10
	}
}));

const TextContainer = styled(Grid)(({ theme }) => ({
	width: '100%',
	maxWidth: '100%',
	padding: '0 20px',
	flex: 1,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column'
}));

const Title = styled(Typography)(({ theme }) => ({
	fontSize: '1.2rem',
	fontFamily: 'Quicksand',
	fontWeight: 500,
	textAlign: 'center',
	maxWidth: '100%',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflow: 'hidden'
}));

const Description = styled(Typography)(({ theme }) => ({
	fontSize: '0.85rem',
	fontFamily: 'Quicksand',
	fontWeight: 500,
	color: theme.palette.text.secondary,
	textAlign: 'center',
	maxWidth: '100%',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflow: 'hidden'
}));

const Image = styled('img')(({ theme }) => ({
	width: '100%',
	height: 'auto',
	aspectRatio: 1,
	objectFit: 'cover'
}));

const Overlay = styled(motion.div)(({ theme }) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	backgroundColor: 'rgba(0, 0, 0, 0.4)',
	borderRadius: 20,
	// opacity: 0,
	pointerEvents: 'none'
}));

function GalleryCard({ title, description, img, galleryID }) {
	const navigate = useNavigate();
	return (
		<Root
			whileHover={{ scale: 1.02, transition: { duration: 0.2 } }}
			whileTap={{ scale: 0.98, transition: { duration: 0.1 } }}
			onClick={() => navigate(`./${galleryID}`)}
		>
			<Image src={img} />

			<TextContainer>
				<Title>{title}</Title>
				<Description>{description}</Description>
			</TextContainer>

			<Overlay
				initial={{ opacity: 0 }}
				whileHover={{ opacity: 1, duration: 0.2 }}
				whileTap={{ opacity: 1, duration: 0.2 }}
			/>
		</Root>
	);
}

export default GalleryCard;
