import React from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Root = styled(Grid)(({ theme }) => ({
	width: '100%',
	height: 'calc(95vh - 120px)',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column'
}));

const ErrorTitle = styled(Typography)(({ theme }) => ({
	fontSize: '3.5rem',
	fontWeight: 900
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
	fontSize: '1.2rem'
}));

function PageNotFound() {
	return (
		<Root>
			<ErrorTitle>404</ErrorTitle>
			<ErrorMessage>Oooops... Looks like you got lost!</ErrorMessage>
		</Root>
	);
}

export default PageNotFound;
