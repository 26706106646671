import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../hooks/AuthContext';

const PublicRoute = ({ children }) => {
	const { isAuthenticated } = useAuth();

	return !isAuthenticated ? children : <Navigate to="/dashboard" />;
};

export default PublicRoute;
