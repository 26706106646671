import React from 'react';
import { DotLottiePlayer } from '@dotlottie/react-player';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const Root = styled(Grid)(({ theme }) => ({
	width: 130,
	height: 130
}));

function GaladiseLoader() {
	return (
		<Root>
			<DotLottiePlayer src="/lottie/GaladiseLoader.lottie" loop autoplay speed={2} />
		</Root>
	);
}

export default GaladiseLoader;
