import React from 'react';
import { useTheme } from '@mui/material/styles';
import { LineChart } from '@mui/x-charts/LineChart';

function AnalyticsChart({ totalViews, totalDownloads, uniqueViews, uniqueDownloads, days }) {
	const theme = useTheme();

	const totalViewsData = totalViews ? generateChartData(totalViews, days) : [];
	const uniqueViewsData = totalViews ? generateChartData(uniqueViews, days) : [];
	const totalDownloadsData = totalDownloads ? generateChartData(totalDownloads, days) : [];
	const uniqueDownloadsData = totalViews ? generateChartData(uniqueDownloads, days) : [];

	const xAxisData = totalViewsData.map((entry) => entry.date);
    
	const totalViewsSeriesData = totalViewsData.map((entry) => entry.views);
	const uniqueViewsSeriesData = uniqueViewsData.map((entry) => entry.views);
	const totalDownloadsSeriesData = totalDownloadsData.map((entry) => entry.views);
	const uniqueDownloadsSeriesData = uniqueDownloadsData.map((entry) => entry.views);

	// Determine the max value in the series data
	const maxYValue = Math.max(
		...[
			...totalViewsSeriesData,
			...uniqueViewsSeriesData,
			...totalDownloadsSeriesData,
			...uniqueDownloadsSeriesData
		]
	);

	const popperStyles = {
		sx: {
			'& .MuiChartsTooltip-root': {
				borderRadius: '10px',
				boxShadow: `0 0 20px -5px ${theme.palette.separator.primary}`,
				border: `1px solid ${theme.palette.separator.primary}`,
				paddingBottom: '5px',
				'& thead .MuiTypography-root': {
					color: theme.palette.text.tertiary,
					textAlign: 'center',
					fontSize: '0.8rem'
				},
				'& thead td': {
					paddingBottom: 0
				},
				'& tbody .MuiTypography-root': {
					color: theme.palette.text.secondary,
					fontSize: '0.95rem',
					fontWeight: 500
				},
				'& td': {
					border: 'none'
				}
			},
			'& .MuiChartsTooltip-mark': {
				boxShadow: 'none',
				border: 'none'
			}
		}
	};

	const lineChartStyles = {
		//change left yAxis label styles
		'& .MuiChartsAxis-left .MuiChartsAxis-tickLabel': {
			strokeWidth: '0.4',
			fill: theme.palette.text.tertiary
		},
		// change bottom label styles
		'& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel': {
			strokeWidth: '0.5',
			fill: theme.palette.text.tertiary
		},
		// bottomAxis Line Styles
		'& .MuiChartsAxis-bottom .MuiChartsAxis-line': {
			stroke: theme.palette.text.tertiary
		},
		// leftAxis Line Styles
		'& .MuiChartsAxis-left .MuiChartsAxis-line': {
			stroke: theme.palette.text.tertiary
		},
		// bottomAxis Line Styles
		'& .MuiChartsAxis-bottom .MuiChartsAxis-tick': {
			stroke: theme.palette.text.tertiary
		},
		// leftAxis Line Styles
		'& .MuiChartsAxis-left .MuiChartsAxis-tick': {
			stroke: theme.palette.text.tertiary
		}
	};

	/**
	 * Formats date to readable format
	 * @param {Date} date
	 * @returns {string} The formatted date
	 */
	const convertDate = (date) => {
		const options = { year: 'numeric', month: 'long', day: 'numeric' };
		return date.toLocaleDateString('en-US', options);
	};

	/**
	 * Converts the value to the relevant format
	 * @param {*} value 
	 * @param {*} context 
	 * @returns The formatted item
	 */
	const valueFormatter = (value, context) => {
		if (context.location === 'tick')
			return days > 1
				? value.toLocaleDateString()
				: value.toLocaleTimeString('en-US', {
						hour: 'numeric',
						minute: 'numeric'
				  });
		return `${convertDate(value)}`;
	};

	/**
	 * Formats the data as needed for the mui chart
	 * @param {{ createdOn: Date }} data 
	 * @param {number} days 
	 * @returns 
	 */
	function generateChartData (data, days) {
		const today = new Date();
		const result = [];
	
		if (days > 1) {
			// Initialize an array for the past 'days' days with zero views
			for (let i = 0; i < days; i++) {
				const date = new Date(today);
				date.setDate(today.getDate() - i);
				result.push({ date: date.toISOString().split('T')[0], views: 0 });
			}
		} else {
			// Initialize an array for the past 'days * 24' hours with zero views
			const hours = days * 24;
			for (let i = 0; i < hours; i++) {
				const date = new Date(today);
				date.setHours(today.getHours() - i, 0, 0, 0);
				result.push({ date: date.toISOString(), views: 0 });
			}
		}
	
		// Reverse the array to have the oldest date or hour first
		result.reverse();
	
		// Accumulate views for each day or hour
		data.forEach((item) => {
			const viewDate = new Date(item.createdOn);
			const viewDateStr =
				days > 1
					? viewDate.toISOString().split('T')[0]
					: new Date(viewDate.setMinutes(0, 0, 0)).toISOString();
	
			const unit = result.find((d) => d.date === viewDateStr);
			if (unit) {
				unit.views += 1;
			}
		});
	
		return days > 1
			? result.map((d) => ({ ...d, date: new Date(d.date) }))
			: result.map((d) => ({ ...d, date: new Date(d.date) }));
	};


	return (

			<LineChart
				slotProps={{
					legend: { hidden: true },
					popper: popperStyles
				}}
				colors={[
					theme.palette.info.main,
					theme.palette.error.main,
					theme.palette.warning.main,
					theme.palette.success.main
				]}
				xAxis={[
					{
						data: xAxisData,
						scaleType: 'utc',
						tickMinStep: days > 1 ? 3600 * 1000 * 24 : 3600 * 1000,
						max: Date.now(),
						valueFormatter
					}
				]}
				yAxis={[
					{
						tickMinStep: 1, // min step: 1
						max: maxYValue + 1 // Add some padding to the max value
					}
				]}
				series={[
					{
						data: totalViewsSeriesData,
						showMark: false,
						curve: 'catmullRom',
						label: 'Total Views'
					},
					{
						data: uniqueViewsSeriesData,
						showMark: false,
						curve: 'catmullRom',
						label: 'Unique Views'
					},
					{
						data: totalDownloadsSeriesData,
						showMark: false,
						curve: 'catmullRom',
						label: 'Total Downloads'
					},
					{
						data: uniqueDownloadsSeriesData,
						showMark: false,
						curve: 'catmullRom',
						label: 'Unique Downloads'
					}
				]}
				height={400}
				sx={lineChartStyles}
			/>
	);
}

export default AnalyticsChart;
