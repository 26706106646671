import * as React from 'react';
import { useState } from 'react';
import { Button, CircularProgress, Dialog, Grid, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CloseRounded } from '@mui/icons-material';
import { useModal } from '../../../../hooks/ModalContext';

const HeaderContainer = styled(Grid)(({ theme }) => ({
	width: '100%',
	padding: 12,
	paddingBottom: 0,
	display: 'flex',
	justifyContent: 'end'
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
	width: 35,
	height: 35
}));

const CloseIcon = styled(CloseRounded)(({ theme }) => ({
	width: 20,
	height: 20,
	color: theme.palette.text.secondary
}));

const Content = styled(Grid)(({ theme }) => ({
	width: '100%',
	padding: '0px 40px 30px',
	gap: '10px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center'
}));

const Title = styled(Typography)(({ theme }) => ({
	fontSize: '1.3rem',
	fontFamily: 'Quicksand',
	fontWeight: 600,
	textAlign: 'center'
}));

const Message = styled(Typography)(({ theme }) => ({
	fontSize: '0.95rem',
	fontFamily: 'Quicksand',
	textAlign: 'center',
	color: theme.palette.text.secondary
}));

const ButtonsContainer = styled(Grid)(({ theme }) => ({
	marginTop: 15,
	display: 'flex',
	gap: '10px'
}));

const PrimaryButton = styled(Button, { shouldForwardProp: (prop) => prop !== 'loading' })(
	({ theme, loading }) => ({
		padding: loading ? '6px 23px' : '6px 34px',
		borderRadius: 20,
		textTransform: 'none',
		'&:disabled': {
			backgroundColor: theme.palette.primary.light,
			color: theme.palette.text.contrast,
			opacity: 0.7
		}
	})
);

const SecondaryButton = styled(Button)(({ theme }) => ({
	padding: '6px 34px',
	borderRadius: 20,
	border: `1px solid ${theme.palette.separator.primary}`,
	textTransform: 'none',
	'&:disabled': {
		color: theme.palette.text.secondary,
		opacity: 0.7
	}
}));

const ButtonLoader = styled(CircularProgress)(({ theme }) => ({
	width: '14px !important',
	height: '14px !important',
	color: theme.palette.text.contrast,
	marginLeft: 8
}));

export default function GenericDialog() {
	const { isOpen, modalData, closeModal } = useModal();
	const [loading, setLoading] = useState(false);

	/**
	 * Flag that checks if the dialog should display two buttons
	 */
	const isDualAction = !!modalData?.onConfirm;

	/**
	 * Wraps the confirm action with loader states.
	 */
	const handleConfirm = async () => {
		setLoading(true);
		await modalData.onConfirm();
		closeModal();
		setLoading(false);
	};

	/**
	 * Maps the relavent function to the primary click dependent
	 * on whether there is one or two buttons.
	 */
	const handlePrimaryClick = isDualAction ? handleConfirm : closeModal;

	return (
		<Dialog
			open={isOpen}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			PaperProps={{
				sx: {
					width: 420,
					borderRadius: '20px'
				}
			}}
		>
			<HeaderContainer>
				<CloseButton onClick={closeModal}>
					<CloseIcon />
				</CloseButton>
			</HeaderContainer>
			<Content>
				<Title>{modalData?.title}</Title>
				<Message>{modalData?.message}</Message>
				<ButtonsContainer>
					{isDualAction && (
						<SecondaryButton disabled={loading} onClick={closeModal}>
							Cancel
						</SecondaryButton>
					)}
					<PrimaryButton
						variant="contained"
						disableElevation
						disabled={loading}
						loading={loading}
						onClick={handlePrimaryClick}
					>
						Confirm
						{loading && <ButtonLoader />}
					</PrimaryButton>
				</ButtonsContainer>
			</Content>
		</Dialog>
	);
}
