import React from 'react';
import { Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

const Root = styled(Skeleton)(({ theme }) => ({
	width: 'calc(33.33vw - 132px)',
	height: 'calc(33.33vw - 30px)',
	borderRadius: 20,
	[theme.breakpoints.down('lg')]: {
		width: 'calc(50vw - 187px)',
		height: 'calc(50vw - 60px)'
	},
	[theme.breakpoints.down('md')]: {
		width: 'calc(100vw - 65px)',
		height: 'calc(100vw + 50px)',
		marginLeft: 10
	}
}));

function GalleryCardSkeleton() {
	return <Root variant="rectangular" />;
}

export default GalleryCardSkeleton;
