import React, { createContext, useContext, useState, useEffect } from 'react';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [modalData, setModalData] = useState(null);

	const openModal = (data) => {
		setModalData(data);
		setIsOpen(true);
	};

	const closeModal = () => {
		setIsOpen(false);
		setTimeout(() => setModalData(null), 150);
	};

	return (
		<ModalContext.Provider value={{ isOpen, modalData, openModal, closeModal }}>
			{children}
		</ModalContext.Provider>
	);
};

export const useModal = () => {
	return useContext(ModalContext);
};
