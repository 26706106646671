import React from 'react';
import { Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

const Root = styled(Skeleton)(({ theme }) => ({
	width: 'calc(25% - 9px)',
	height: '161.4px !important',
	borderRadius: 20,
	[theme.breakpoints.down('sm')]: {
		width: 'calc(50% - 5px)'
	}
}));

function GalleryAnalyticCardSkeleton() {
	return <Root variant="rectangular" />;
}

export default GalleryAnalyticCardSkeleton;
