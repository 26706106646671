import Cookies from 'js-cookie';
import { BACKEND_URL } from '../config/env';

/**
 * Logs in the user with an email and password
 * @param {string} email
 * @param {string} password
 */
export const login = async (email, password) => {
	const response = await fetch(`${BACKEND_URL}/app/auth/login`, {
		method: 'POST',
		headers: { 'Content-type': 'application/json' },
		body: JSON.stringify({ email, password })
	});

	const loginResponse = await response?.json();

	if (loginResponse.success) {
		setTokens(loginResponse.tokens, loginResponse.user);
		return true;
	}

	console.log(`loginResponse: ${JSON.stringify(loginResponse)}`);
	return false;
};

/**
 * Sets the tokens in browser cookies
 * @param {object} tokens
 * @param {object} user
 */
export const setTokens = (tokens, user) => {
	const expiration = Date.now() + tokens.expiresIn * 1000;

	Cookies.set('galadise_idToken', tokens.idToken, { expires: 1 });
	Cookies.set('galadise_refreshToken', tokens.refreshToken, { expires: 7 });
	Cookies.set('galadise_expiration', expiration, { expires: 7 });

	if (user?.email && user?.name) {
		Cookies.set('galadise_name', user.name, { expires: 180 });
		Cookies.set('galadise_email', user.email, { expires: 180 });
	}
};

/**
 * Removes the tokens from the browser cookies
 */
export const removeTokens = () => {
	Cookies.remove('galadise_idToken');
	Cookies.remove('galadise_refreshToken');
	Cookies.remove('galadise_expiration');
	Cookies.remove('galadise_name');
	Cookies.remove('galadise_email');
};

/**
 * Retrieves the tokens from the browser cookies
 * @returns An object with all of the cookie data
 */
export const getTokens = () => {
	const idToken = Cookies.get('galadise_idToken');
	const refreshToken = Cookies.get('galadise_refreshToken');
	const expiration = Cookies.get('galadise_expiration');
	const name = Cookies.get('galadise_name');
	const email = Cookies.get('galadise_email');

	return { idToken, refreshToken, expiration, name, email };
};

/**
 * Verifies whether the idToken is valid
 * @param {string} idToken
 */
export const verifyToken = async (idToken) => {
	const response = await fetch(`${BACKEND_URL}/app/auth/verify`, {
		method: 'GET',
		headers: { 'Content-type': 'application/json', Authorization: `Bearer ${idToken}` }
	});

	const isValid = await response?.json();

	return isValid;
};

/**
 * Refreshes the token
 * @param {string} refreshToken
 */
export const refreshIdToken = async (refreshToken) => {
	const response = await fetch(`${BACKEND_URL}/app/auth/refresh`, {
		method: 'GET',
		headers: { 'Content-type': 'application/json', Authorization: `Bearer ${refreshToken}` }
	});

	const refreshResponse = await response?.json();

	if (refreshResponse.success) {
		setTokens(refreshResponse.tokens, refreshResponse.user);
		return true;
	}

	return false;
};

/**
 * Checks whether the user is authenticated
 * @returns true if the user has valid credentials, false otherwise
 */
export const isAuthenticated = async () => {
	const timestamp = Date.now();
	let isValid = false;

	const idToken = Cookies.get('galadise_idToken');
	const refreshToken = Cookies.get('galadise_refreshToken');
	const expiration = Cookies.get('galadise_expiration');

	if (idToken && expiration > timestamp) {
		isValid = await verifyToken(idToken);
	}

	if (!isValid && refreshToken) {
		isValid = await refreshIdToken(refreshToken);
	}

	return isValid;
};
