import React from 'react';
import { Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

const Root = styled(Skeleton)(({ theme }) => ({
	width: '100%',
	height: '130px !important',
	borderRadius: 20
}));

function GalleryControlSkeleton() {
	return <Root variant="rectangular" />;
}

export default GalleryControlSkeleton;
