import React from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import GaladiseLoader from './GaladiseLoader';

const Root = styled(Grid)(({ theme }) => ({
	width: '100vw',
	height: '100dvh',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
}));

function PageLoader() {
	return (
		<Root>
			<GaladiseLoader />
		</Root>
	);
}

export default PageLoader;
