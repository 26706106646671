import React from 'react';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import MobileMenuItem from './MobileMenuItem';

const Root = styled(motion.div)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center'
}));

function MobileMenuItems({ show, setOpen }) {
	/**
	 * Closes the mobile menu when an item is clicked on
	 */
	const closeMenu = () => {
		setOpen(false);
	};

	return (
		<>
			{show && (
				<Root
					animate={{
						y: 0,
						opacity: 1
					}}
					initial={{ y: -10, opacity: 0 }}
					transition={{
						ease: [0.77, 0, 0.175, 1],
						duration: 0.3,
						delay: 0.2
					}}
				>
					<MobileMenuItem to="/dashboard" label="Home" onClick={closeMenu} />
					<MobileMenuItem
						to="/dashboard/galleries"
						label="Galleries"
						onClick={closeMenu}
					/>
				</Root>
			)}
		</>
	);
}

export default MobileMenuItems;
