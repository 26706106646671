import React from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PrivateRoute } from '../Router';
import SideMenu from './PageComponents/Dashboard/SideMenu';
import AppHeader from './PageComponents/Dashboard/AppHeader';
import { AppDataProvider } from '../../hooks/AppDataContext';
import DashboardRoutes from '../DashboardRoutes';
import { ModalProvider } from '../../hooks/ModalContext';
import GenericDialog from '../Generic/Dialogs/GenericDialog';

const Root = styled(Grid)(({ theme }) => ({
	width: '100vw',
	height: '100dvh',
	display: 'flex',
	background: theme.palette.background.secondary
}));

const Body = styled(Grid)(({ theme }) => ({
	flex: 1,
	height: '100dvh',
	display: 'flex',
	flexDirection: 'column',
	position: 'relative'
}));

const Content = styled(Grid)(({ theme }) => ({
	width: '100%',
	height: '100%',
	overflow: 'auto',
	'&::-webkit-scrollbar': {
		width: '10px'
	},
	'&::-webkit-scrollbar-track': {
		background: theme.palette.background.primary
	},
	'&::-webkit-scrollbar-thumb': {
		backgroundColor: theme.palette.primary.light,
		borderRadius: '10px',
		border: `2px solid ${theme.palette.background.primary}`
	},
	'&::-webkit-scrollbar-thumb:hover': {
		backgroundColor: theme.palette.primary.main
	}
}));

function Dashboard() {
	return (
		<PrivateRoute>
			<AppDataProvider>
				<ModalProvider>
					<Root>
						<SideMenu />
						<Body>
							<AppHeader />
							<Content>
								<DashboardRoutes />
							</Content>
						</Body>
					</Root>
					<GenericDialog />
				</ModalProvider>
			</AppDataProvider>
		</PrivateRoute>
	);
}

export default Dashboard;
