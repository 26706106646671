import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from '../hooks/ScrollToTop';
import Dashboard from './Pages/Dashboard';
import PageNotFound from './Pages/PageNotFound';
import Login from './Pages/Login';
import { AuthProvider } from '../hooks/AuthContext';
import AuthGuard from './AuthGuard';
import Root from './Pages/Root';

function AppRoutes() {
	return (
		<AuthProvider>
			<AuthGuard>
				<BrowserRouter>
					<ScrollToTop />
					<Routes>
						<Route exact path="/" element={<Root />} />
						<Route exact path="/dashboard/*" element={<Dashboard />} />
						<Route exact path="/login" element={<Login />} />
						<Route path="*" element={<PageNotFound />} />
					</Routes>
				</BrowserRouter>
			</AuthGuard>
		</AuthProvider>
	);
}

export default AppRoutes;
