import React, { createContext, useState, useContext } from 'react';

const AppDataContext = createContext();

export const AppDataProvider = ({ children }) => {
	const [galleries, setGalleries] = useState({ data: [], loading: false, loadedAt: 0 });

	return (
		<AppDataContext.Provider value={{ galleries, setGalleries }}>
			{children}
		</AppDataContext.Provider>
	);
};

export const useAppData = () => useContext(AppDataContext);
