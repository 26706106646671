import createTheme from '@mui/material/styles/createTheme';

const palette = {
	primary: { light: '#6B7F82', main: '#3C4D4F', dark: '#1E2019' },
	secondary: { light: '#C49991', main: '#936D66', dark: '#4A312C' },
	text: { primary: '#313B43', secondary: '#54626D', tertiary: '#7D8D99', contrast: '#F7F7F7' },
	surface: { light: '#f4e5e2', main: '#EEF2FA', dark: '#C9D7F2', contrast: '#FBFDFF' },
	background: { primary: '#F9FBFF', secondary: '#F6F7F9' },
	separator: { primary: '#DDE1E8', secondary: '#EEF2FA' },
	error: { light: '#D6B9C0', main: '#92374D', dark: '#561222' },
	success: { light: '#A1DF83', main: '#579839', dark: '#285413' },
	info: { light: '#BFE1FA', main: '#4C89B5', dark: '#1F547A' },
	warning: { light: '#F6CA96', main: '#D08F44', dark: '#A05A07' }
};

const theme = createTheme({
	palette: palette,
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1536,
			xxl: 1900
		}
	},
	typography: {
		fontFamily: [
			'Plus Jakarta Sans',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		].join(','),
		allVariants: {
			color: palette.text.primary
		}
	},
	components: {
		MuiDivider: {
			styleOverrides: {
				root: {
					backgroundColor: palette.separator.secondary
				}
			}
		}
	}
});

export { theme };
