import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import MobileMenuToggle from './MobileMenuToggle';
import MobileMenuView from './MobileMenuView';

const Root = styled(Grid)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		display: 'none'
	}
}));

function MobileMenu() {
	const [open, setOpen] = useState(false);

	return (
		<Root>
			<MobileMenuToggle open={open} setOpen={setOpen} />
			<MobileMenuView open={open} setOpen={setOpen} />
		</Root>
	);
}

export default MobileMenu;
