import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import UserPopup from './UserPopup';
import { getTokens } from '../../../../services/authService';

const Root = styled(Grid)(({ theme }) => ({
	width: 50,
	height: 50,
	borderRadius: '50%',
	backgroundColor: theme.palette.surface.dark,
	boxShadow: `0 0 20px -10px ${theme.palette.separator.primary}`,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	cursor: 'pointer',
	userSelect: 'none',
	zIndex: 900
}));

const Title = styled(Typography)(({ theme }) => ({
	fontSize: '1rem',
	fontFamily: 'Quicksand',
	fontWeight: 500
}));

function UserProfile() {
	const [popupAnchor, setPopupAnchor] = useState(null);

	const openPopup = (event) => {
		setPopupAnchor(event.currentTarget);
	};

	const closePopup = () => {
		setPopupAnchor(null);
	};

	/**
	 * Extracts the initials from the name
	 * @param {string} name
	 * @returns The extracted initials
	 */
	const getInitials = (name) => {
		// Split the name into an array of words
		const words = name.split(' ');

		// If there's only one word, return the first letter of that word
		if (words.length === 1) {
			return words[0].charAt(0).toUpperCase();
		}

		// If there are multiple words, return the first letter of the first word and the first letter of the last word
		const firstInitial = words[0].charAt(0).toUpperCase();
		const lastInitial = words[words.length - 1].charAt(0).toUpperCase();

		return firstInitial + lastInitial;
	};

	const { name, email } = getTokens();
	const initials = getInitials(name);

	return (
		<>
			<Root onClick={openPopup}>
				<Title>{initials}</Title>
			</Root>
			<UserPopup
				anchorEl={popupAnchor}
				handleClose={closePopup}
				initials={initials}
				name={name}
				email={email}
			/>
		</>
	);
}

export default UserProfile;
