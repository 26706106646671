import React from 'react';
import { Popover, Typography, Grid, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { LogoutRounded } from '@mui/icons-material';
import { removeTokens } from '../../../../services/authService';
import { useAuth } from '../../../../hooks/AuthContext';

const UserContainer = styled(Grid)(({ theme }) => ({
	padding: 5,
	display: 'flex',
	alignItems: 'center'
}));

const UserAvatar = styled(Grid)(({ theme }) => ({
	width: 50,
	height: 50,
	borderRadius: '50%',
	backgroundColor: theme.palette.surface.dark,
	boxShadow: `0 0 20px -10px ${theme.palette.separator.primary}`,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	userSelect: 'none'
}));

const Title = styled(Typography)(({ theme }) => ({
	fontSize: '1rem',
	fontFamily: 'Quicksand',
	fontWeight: 500
}));

const UserDetailsContainer = styled(Grid)(({ theme }) => ({
	marginInlineStart: 15,
	marginBottom: 5
}));

const Name = styled(Typography)(({ theme }) => ({
	maxWidth: 180,
	overflow: 'hidden',
	textOverflow: 'ellipsis ',
	whiteSpace: 'nowrap',
	fontSize: '0.9rem',
	fontWeight: 500
}));

const ActionsContainer = styled(Grid)(({ theme }) => ({
	marginTop: 25
}));

const Email = styled(Typography)(({ theme }) => ({
	maxWidth: 180,
	overflow: 'hidden',
	textOverflow: 'ellipsis ',
	whiteSpace: 'nowrap',
	fontSize: '0.7rem',
	color: theme.palette.text.secondary
}));

const ActionButton = styled(Button)(({ theme }) => ({
	width: '100%',
	backgroundColor: theme.palette.background.secondary,
	textTransform: 'none'
}));

const LogoutIcon = styled(LogoutRounded)(({ theme }) => ({
	width: 18,
	marginInlineEnd: 10,
	color: theme.palette.text.primary
}));

function UserPopup({ anchorEl, handleClose, initials, name, email }) {
	const { setLoggedOut } = useAuth();
	const theme = useTheme();

	const popoverStyle = {
		zIndex: 1100,
		'& .MuiPaper-root': {
			width: '260px',
			padding: '15px',
			borderRadius: '15px',
			backgroundColor: theme.palette.surface.contrast,
			boxShadow: `0 3px 25px -5px ${theme.palette.separator.primary}`
		}
	};

	/**
	 * Removes the cookies and set the context state isAuthenticated to false
	 */
	const handleLogout = () => {
		removeTokens();
		setLoggedOut();
	};

	return (
		<Popover
			id={anchorEl ? 'user-popup' : undefined}
			open={Boolean(anchorEl)}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center'
			}}
			sx={popoverStyle}
		>
			<UserContainer>
				<UserAvatar>
					<Title>{initials}</Title>
				</UserAvatar>
				<UserDetailsContainer>
					<Name>{name}</Name>
					<Email>{email}</Email>
				</UserDetailsContainer>
			</UserContainer>
			<ActionsContainer>
				<ActionButton onClick={handleLogout}>
					<LogoutIcon />
					Logout
				</ActionButton>
			</ActionsContainer>
		</Popover>
	);
}

export default UserPopup;
