import React from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Root = styled(Grid)(({ theme }) => ({
	display: 'flex',
	width: 'calc(25% - 9px)',
	padding: 15,
	backgroundColor: theme.palette.surface.light,
	borderRadius: 20,
	flexDirection: 'column',
	justifyContent: 'space-between',
	[theme.breakpoints.down('sm')]: {
		width: 'calc(50% - 5px)'
	}
}));

const TitleContainer = styled(Grid)(({ theme }) => ({
	marginTop: 5,
	padding: 7,
	display: 'flex',
	flexDirection: 'column'
}));

const Title = styled(Typography)(({ theme }) => ({
	fontSize: '1.7rem',
	fontFamily: 'Quicksand',
	fontWeight: 700,
	color: theme.palette.secondary.dark
}));

const Description = styled(Typography)(({ theme }) => ({
	fontSize: '0.9rem',
	fontFamily: 'Quicksand',
	fontWeight: 500,
	color: theme.palette.secondary.main
}));

const IconContainer = styled(Grid)(({ theme }) => ({
	width: 50,
	height: 50,
	backgroundColor: theme.palette.background.secondary,
	borderRadius: 10,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	'& svg': {
		width: '26px',
		height: '26px',
		stroke: theme.palette.secondary.dark
	}
}));

function GalleryAnalyticCard({ amount, title, Icon }) {
	return (
		<Root>
			<IconContainer>
				<Icon />
			</IconContainer>
			<TitleContainer>
				<Title>{amount}</Title>
				<Description>{title}</Description>
			</TitleContainer>
		</Root>
	);
}

export default GalleryAnalyticCard;
