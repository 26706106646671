import { BACKEND_URL } from '../config/env';
import { getTokens } from './authService';

/**
 * Gets all galleries associated with the account from the database
 */
export const getGalleries = async () => {
	const { idToken } = getTokens();

	const response = await fetch(`${BACKEND_URL}/app/galleries`, {
		method: 'GET',
		headers: { 'Content-type': 'application/json', Authorization: `Bearer ${idToken}` }
	});

	const galleriesResponse = await response?.json();

	return galleriesResponse;
};

/**
 * Gets gallery stats for the given gallery ID
 */
export const getGalleryStats = async (galleryID) => {
	const { idToken } = getTokens();

	const response = await fetch(`${BACKEND_URL}/app/galleries/stats/${galleryID}`, {
		method: 'GET',
		headers: { 'Content-type': 'application/json', Authorization: `Bearer ${idToken}` }
	});

	const galleryStatsResponse = await response?.json();

	return galleryStatsResponse;
};

/**
 * Deletes a gallery from the database and from the bucket
 */
export const deleteGallery = async (galleryID) => {
	const { idToken } = getTokens();

	const response = await fetch(`${BACKEND_URL}/app/galleries/${galleryID}`, {
		method: 'DELETE',
		headers: { 'Content-type': 'application/json', Authorization: `Bearer ${idToken}` }
	});

	const galleryStatsResponse = await response?.json();

	console.log(`galleryStatsResponse: ${JSON.stringify(galleryStatsResponse)}`);

	return galleryStatsResponse;
};
