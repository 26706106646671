import React from 'react';
import { Grid, Typography, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import LinkDisplay from './LinkDisplay';
import { useModal } from '../../../../../../hooks/ModalContext';
import { deleteGallery } from '../../../../../../services/galleryService';
import { useAppData } from '../../../../../../hooks/AppDataContext';
import { useNavigate } from 'react-router-dom';
import GalleryControlActions from './GalleryControlActions';

const ControlContainer = styled(Grid)(({ theme }) => ({
	width: '100%',
	padding: 20,
	borderRadius: 20,
	backgroundColor: theme.palette.surface.contrast,
	boxShadow: `0 0 20px -10px ${theme.palette.separator.primary}`,
	display: 'flex',
	justifyContent: 'space-between',
	[theme.breakpoints.down('sm')]: {
		flexDirection: 'column'
	}
}));

const LeftContainer = styled(Grid)(({ theme }) => ({
	display: 'flex'
}));

const RightContainer = styled(Grid)(({ theme }) => ({
	display: 'flex'
}));

const Title = styled(Typography)(({ theme }) => ({
	marginTop: 7,
	marginInlineStart: 7,
	fontSize: '1rem',
	fontFamily: 'Quicksand',
	fontWeight: 700,
	color: theme.palette.success.main
}));

const Thumbnail = styled('img')(({ theme }) => ({
	height: 90,
	width: 'auto',
	borderRadius: 10
}));

const LinksContainer = styled(Grid)(({ theme }) => ({
	marginLeft: 20,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	gap: 12
}));

const DesktopLinkDisplay = styled(LinkDisplay)(({ theme }) => ({
	[theme.breakpoints.down('sm')]: {
		display: 'none'
	}
}));

const MobileLinkDisplay = styled(LinkDisplay)(({ theme }) => ({
	marginTop: '25px',
	[theme.breakpoints.up('sm')]: {
		display: 'none'
	}
}));

const DesktopActions = styled(GalleryControlActions)(({ theme }) => ({
	[theme.breakpoints.down('sm')]: {
		display: 'none'
	}
}));

const MobileActions = styled(GalleryControlActions)(({ theme }) => ({
	[theme.breakpoints.up('sm')]: {
		display: 'none'
	}
}));

function GalleryControl({ gallery }) {
	const galleryUrl = `https://gallery.galadise.com/${gallery._id}`;
	const { openModal } = useModal();
	const { galleries, setGalleries } = useAppData();
	const navigate = useNavigate();

	const handleDeleteGallery = async () => {
		await deleteGallery(gallery._id);
		const updatedGalleriesData = galleries.data.filter((g) => g._id !== gallery._id);
		setGalleries({ ...galleries, data: updatedGalleriesData });
		navigate('../galleries');
	};

	const onDeleteClick = () => {
		const modalData = {
			title: 'Confirmation Required',
			message: `Are you sure you want to delete this gallery?\nThis action cannot be undone.`,
			onConfirm: handleDeleteGallery
		};

		openModal(modalData);
	};

	return (
		<ControlContainer>
			<LeftContainer>
				<Thumbnail src={gallery?.thumbnail} />
				<LinksContainer>
					<Title>Published</Title>
					<DesktopLinkDisplay url={galleryUrl} />
					<MobileActions
						onView={() => window.open(galleryUrl)}
						onDelete={onDeleteClick}
					/>
				</LinksContainer>
			</LeftContainer>
			<RightContainer>
				<DesktopActions onView={() => window.open(galleryUrl)} onDelete={onDeleteClick} />
			</RightContainer>
			<MobileLinkDisplay url={galleryUrl} />
		</ControlContainer>
	);
}

export default GalleryControl;
