import React, { useState } from 'react';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const InputField = styled(TextField)(({ theme }) => ({
	width: '80%'
}));

const ShowPasswordIcon = styled(Visibility)(({ theme }) => ({
	width: 18,
	height: 18,
	color: theme.palette.primary.light
}));

const HidePasswordIcon = styled(VisibilityOff)(({ theme }) => ({
	width: 18,
	height: 18,
	color: theme.palette.primary.light
}));

function PasswordInput(props) {
	const [showPassword, setShowPassword] = useState(false);

	return (
		<InputField
			size="small"
			variant="standard"
			placeholder="Password"
			type={showPassword ? 'text' : 'password'}
			autoComplete="password"
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={() => setShowPassword(!showPassword)}
						>
							{showPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
						</IconButton>
					</InputAdornment>
				)
			}}
			{...props}
		/>
	);
}

export default PasswordInput;
