import React, { useEffect } from 'react';
import { useState } from 'react';
import { useAuth } from '../hooks/AuthContext';
import PageLoader from './Generic/PageLoader';
import { isAuthenticated } from '../services/authService';

function AuthGuard(props) {
	const { setLoggedIn, setLoggedOut } = useAuth();
	const [loaded, setLoaded] = useState(false);

	/**
	 * Checks to see if the user has valid cookies
	 */
	const checkCookies = async () => {
		const isValid = await isAuthenticated();

		if (isValid) {
			setLoggedIn();
			setLoaded(true);
			return;
		}

		setLoggedOut();
		setLoaded(true);
	};

	useEffect(() => {
		checkCookies();
	}, []);

	return <>{loaded ? props.children : <PageLoader />}</>;
}

export default AuthGuard;
